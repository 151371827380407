import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
	ErrorBoundary,
	LayoutProps,
	Menu,
	Notifications,
	useGlobalStateContext,
	usePiletApi,
} from 'piral';
import * as React from 'react';
import { FunctionComponent, Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import { FetchError } from '../../errors/fetch-error';
import { getToken, setToken } from '../../hooks/auth';

export const LayoutContent: FunctionComponent<LayoutProps> = ({ children }) => {
	const globalStateContext = useGlobalStateContext();
	const queryClient = useQueryClient();
	const piletApi = usePiletApi();

	const { isLoading, isError } = useQuery<unknown, FetchError>({
		queryKey: ['withToken', 'users/me'],
		queryFn: async (config) => {
			const token = getToken();
			if (!token) {
				// No token set then unset user
				globalStateContext.setUser(undefined, {}, {});
				globalStateContext.dispatch((state) => ({
					...state,
					initialLoading: false,
				}));
				return null;
			}

			const response = await piletApi.serviceApi.users.getMe({
				signal: config.signal,
			});
			const { userID, name, email } = response;
			globalStateContext.setUser({
				id: String(userID),
				firstName: name,
				mail: email,
			});
			globalStateContext.dispatch((state) => ({
				...state,
				initialLoading: false,
			}));
			return response;
		},
		retry: false,
	});

	if (isError) {
		setToken('', queryClient);
		return <Redirect to={'/auth/login'} />;
	}

	if (isLoading) {
		return <div>Loading...</div>;
	}

	return (
		<div className="layout layout-default">
			<Notifications />
			<Menu type="general" />
			<ErrorBoundary errorType={'unknown'} piral={piletApi}>
				<Suspense fallback={'Loading...'}>{children}</Suspense>
			</ErrorBoundary>
		</div>
	);
};
