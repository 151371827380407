{
  "name": "@hub/hub-app",
  "version": "0.1.37",
  "description": "",
  "scripts": {
    "start": "piral debug",
    "build": "piral build",
    "declaration": "piral declaration"
  },
  "app": "./src/index.html",
  "types": "./dist/index.d.ts",
  "extraTypes": "./types/index.d.ts",
  "bundledDependencies": [
    "react",
    "react-dom",
    "react-router",
    "react-router-dom",
    "@hub/ui"
  ],
  "importmap": {
    "inherit": [
      "piral-base",
      "piral-core"
    ]
  }
}
