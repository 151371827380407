import { DashboardTileProps } from 'piral-dashboard';
import * as React from 'react';
import { FC, Suspense } from 'react';
import { HCol } from '@hub/ui-react';
import { ErrorBoundary, usePiletApi } from 'piral';

const DashboardTile: FC<DashboardTileProps> = ({ columns, children }) => {
  const piletApi = usePiletApi();
  return (
    <HCol size={columns} className={'rounded-lg h-40 w-full overflow-hidden'}>
      <ErrorBoundary errorType={'unknown'} piral={piletApi}>
        <Suspense fallback={'Loading...'}>{children}</Suspense>
      </ErrorBoundary>
    </HCol>
  );
};

export default DashboardTile;
