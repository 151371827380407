import { usePiletApi } from 'piral';
import * as React from 'react';
import { HButton, HCol, HContainer, HRow, HTextField } from '@hub/ui-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
	IntegrationApiKey,
	IUserResponse,
	ProfileData,
} from '../../../types/services/user-service';
import { FetchError } from '../../errors/fetch-error';


const integrations: {type: IntegrationApiKey['type'], url: string; }[] = [
	{ type: 'noko', url: 'https://twn.nokotime.com/time/integrations/freckle_api' },
	{ type: 'jira', url: 'https://jira.twn.ee/secure/ViewProfile.jspa?selectedTab=com.atlassian.pats.pats-plugin:jira-user-personal-access-tokens' },
	{ type: 'confluence', url: 'https://wiki.twn.ee/plugins/personalaccesstokens/usertokens.action' },
	{ type: 'bamboo', url: '' },
	{ type: 'bitbucket', url: '' },
];


export default () => {
	const piletApi = usePiletApi();
	const [profileIntegrationKeys, setProfileIntegrationKeys] = React.useState<IntegrationApiKey[]>([]);

	const { isLoading, data: profileData } = useQuery<IUserResponse, FetchError>({
		queryKey: ['withToken', 'users/me'],
		queryFn: async (config) => {

			return await piletApi.serviceApi.users.getMe({
				signal: config.signal,
			});
		},
	});

	React.useEffect(() => {
		if (profileData?.profile) {
			if ( Array.isArray(profileData.profile.integrationKeys) ) {
				setProfileIntegrationKeys(profileData.profile.integrationKeys);
			}
		}
	}, [profileData]);

	const { isPending, mutate } = useMutation({
		mutationFn: (data: ProfileData) => {
			return piletApi.fetchPut('/v1/users/me', {
				body: data,
			});
		},
	});

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		mutate({
			integrationKeys: profileIntegrationKeys,
		});
	};

	return (
		<HContainer className="mt-4 relative overflow-hidden bg-white shadow-md p-4 ">
			<div className="mx-4 border-b pt-3">
				<h1 className="font-bolder text-2xl">Profiil</h1>
			</div>
			<form
				className="mx-4 border-b py-3 flex"
				style={{ flexDirection: 'column', gap: '1rem' }}
				onSubmit={handleSubmit}
			>
				<h2 className="font-bolder text-1xl">Integration api keys</h2>
				<HRow>
					{integrations.map((integration) => {
						const integrationDataIndex = profileIntegrationKeys.findIndex(
							(data) => data.type === integration.type,
						);

						return (
							<HCol size={6}>
								<HTextField
									value={profileIntegrationKeys[integrationDataIndex]?.key ?? ''}
									label={integration.type}
									required
									disabled={isLoading || isPending}
									onInput={(e) => {
										const updatedData = profileIntegrationKeys.reduce<IntegrationApiKey[]>(
											(acc, data) => {
												if (data.type === integration.type) {
													return acc;
												}
												acc.push(data);
												return acc;
											},
											[
												{
													type: integration.type,
													key: (e.target as unknown as HTMLInputElement).value,
												},
											],
										);
										return setProfileIntegrationKeys(updatedData);
									}}
								/>
								<a style={{
									overflowWrap: 'anywhere',
								}} href={integration.url}>{integration.url}</a>
							</HCol>
						);
					})}
				</HRow>
				<HRow>
					<HButton type={'submit'} disabled={isLoading || isPending}>
						{isPending ? 'Loading...' : 'Save'}
					</HButton>
				</HRow>
			</form>
		</HContainer>
	);
};
