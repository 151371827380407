import { DashboardContainerProps } from 'piral-dashboard';
import * as React from 'react';
import { FC } from 'react';
import { HAlert, HCol, HContainer, HRow } from '@hub/ui-react';
import { IDashboardExternalTools } from '../../../types/dashboard';

const externalTools: IDashboardExternalTools[] = [
  {
    title: 'Referentsbaas',
    link: 'https://referentsid.twn.ee/',
  },
  {
    title: 'Upload',
    link: 'https://upload.twn.ee/'
  },
  {
    title: 'Palgaarvestus',
    link: 'https://palgaarvestus.twn.ee/'
  }
]

const DashboardContainer: FC<DashboardContainerProps> = ({ children }) => (
  <HContainer className="mt-6">
    <HRow>
      <HCol size={12}>
        <h1 className="text-center mt-8 mb-6 app-title">TWN dashboard</h1>
      </HCol>
    </HRow>
    <div className="dashboard-content">
      {Array.isArray(children) && children.length !== 0 ? (
        <HRow alignItems={'center'} justifyContent={'center'}>
          {children}
        </HRow>
      ) : (
        <HRow>
          <HCol size={12}>
            <HAlert variant="error" className="text-center">
              No tools registered
            </HAlert>
          </HCol>
        </HRow>
      )}
      <HRow style={{
        marginTop: '3rem'
      }}>
        <HCol size={12}>
          <h2 className="text-center mt-8 mb-6 app-title">External tools</h2>
        </HCol>
      </HRow>
      <HRow>
        {
          externalTools.map((tool, index) => (
            <HCol size={4} key={tool.link}>
              <a href={tool.link} target="_blank" rel="noreferrer" className="text-center h-full w-full" style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0.3rem',
                backgroundColor: 'rgb(30, 35, 68)',
                borderRadius: '0.5rem',
                color: '#fff',
                textTransform: 'uppercase',
                fontWeight: 600,
                fontSize: '1.4rem',
                minHeight: '9rem',
              }}>
                {tool.title}
              </a>
            </HCol>
          ))
        }
      </HRow>
    </div>
  </HContainer>
);

export default DashboardContainer;
