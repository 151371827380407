import {
	IJiraErrorCollection,
	IJiraFilterResponse, IJiraIssueTypeScheme, IJiraIssueTypeSchemeList,
	IJiraProjectResponse, IJiraWorkflow, IJiraWorkflowScheme,
} from '../../types/services/jira-service';
import {
	IFetchFunctions,
	IServicesApi,
} from '../../types/services/service-api';

const getJiraService = (
	fetchFunctions: IFetchFunctions,
): IServicesApi['serviceApi']['jira'] => ({
	async filterProjects(queryParams, options) {
		const urlSearchParams = new URLSearchParams(
			queryParams as Record<string, string>,
		);
		return fetchFunctions.fetchGet<IJiraFilterResponse>(
			`/v1/atlassian/jira/projects/picker?${urlSearchParams.toString()}`,
			options,
		);
	},

	async getProjectByKey(key, options) {
		return fetchFunctions.fetchGet<IJiraProjectResponse>(
			`/v1/atlassian/jira/projects/${key}`,
			options,
		);
	},

	async validateProjectKey(projectKey, options) {
		return fetchFunctions.fetchGet<IJiraErrorCollection>(
			`/v1/atlassian/jira/projects/validate/${projectKey}`,
			options,
		);
	},

	async getWorkflows(options, name) {
		return fetchFunctions.fetchGet<IJiraWorkflow|IJiraWorkflow[]>(
			`/v1/atlassian/jira/workflows${name ? `?workflowName=${name}` : ''}`,
			options,
		);
	},

	async getAllowedWorkflowSchemes(options) {
		return fetchFunctions.fetchGet<IJiraWorkflowScheme[]>(
			"/v1/atlassian/jira/workflow-scheme/allowed",
			options,
		);
	},
	async getIssueTypeSchemes(options) {
		return fetchFunctions.fetchGet<IJiraIssueTypeSchemeList>(
			"/v1/atlassian/jira/issue-type-scheme",
			options,
		);
	},

	async getAllowedIssueTypeSchemes(options) {
		return fetchFunctions.fetchGet<IJiraIssueTypeScheme[]>(
			"/v1/atlassian/jira/issue-type-scheme/allowed",
			options,
		);
	},

	async getIssueTypeScheme(schemeId, options) {
		return fetchFunctions.fetchGet<IJiraIssueTypeScheme>(
			`/v1/atlassian/jira/issue-type-scheme/${schemeId}`,
			options,
		);
	},
});
export default getJiraService;
