import { HAlert, HCol, HContainer, HRow } from '@hub/ui-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
	ComponentsState,
	ErrorBoundary,
	ErrorComponentsState,
	LayoutProps,
	MenuItemProps,
	SwitchErrorInfo,
	useGlobalState,
	usePiletApi,
} from 'piral';
import * as React from 'react';
import { FunctionComponent, Suspense } from 'react';
import { useRouteMatch } from 'react-router';
import { Redirect } from 'react-router-dom';
import { LayoutContent } from './LayoutContent';
import { Navigation } from './menu';
import DashboardContainer from '../../components/dashboard/DashboardContainer';
import DashboardTile from '../../components/dashboard/DashboardTile';


const queryClient = new QueryClient();

const MenuItem: FunctionComponent<MenuItemProps> = ({ children }) => children;

export const errors: Partial<ErrorComponentsState> = {
	not_found: () => (
		<HAlert variant="error" closable alertTitle="404 - Not Found">
			<p>
				The page you are looking for might have been removed, had its name
				changed, or is temporarily unavailable.
			</p>
		</HAlert>
	),
};

export const DefaultLayout: FunctionComponent<LayoutProps> = (layoutProps) => {
	const initialLoading = useGlobalState((s) => s.initialLoading);
	const user = useGlobalState((s) => s.user);
	const match = useRouteMatch(['/auth/login', '/auth']);

	if (!initialLoading) {
		if (typeof user === 'undefined') {
			if (!match) return <Redirect to="/auth/login" />;
		} else if (match) return <Redirect to="/" />;
	}

	return (
		<QueryClientProvider client={queryClient}>
			<LayoutContent {...layoutProps}>{layoutProps.children}</LayoutContent>
		</QueryClientProvider>
	);
};

export const layout: Partial<ComponentsState> = {
	ErrorInfo: (props) => (
		<HAlert variant="error" alertTitle="Something went wrong" closable>
			<SwitchErrorInfo {...props} />
		</HAlert>
	),
	Layout: DefaultLayout,
	MenuContainer: ({ children, type }) => (
		<Navigation type={type}>{children}</Navigation>
	),
	MenuItem,
	NotificationsHost: ({ children }) => (
		<div className="notifications">{children}</div>
	),
	NotificationsToast: ({ options, onClose, children }) => (
		<div className={`notification-toast ${options.type}`}>
			<div className="notification-toast-details">
				{options.title && (
					<div className="notification-toast-title">{options.title}</div>
				)}
				<div className="notification-toast-description">{children}</div>
			</div>
			<button
				type={'button'}
				className="notification-toast-close"
				onClick={onClose}
				onKeyDown={(e) =>
					['enter', 'space'].includes(e.key) ? onClose() : null
				}
			/>
		</div>
	),
	DashboardContainer,
	DashboardTile,
};
